import React from 'react';
import { Icon, Link, Text } from '@goodfynd/react-web.lib.ui';
import { convertDistance, getPreciseDistance } from 'geolib';

import config from '../../config';
import strings from '../../config/strings';
import { dateUtil, numberUtil, storageUtil } from '../../utils';

import ProductImage from './ProductImage';
import {
  StyledCardContainer,
  StyledCardContent,
  StyledCardContentTop,
  StyledDescription,
  StyledQuickInfo,
} from './styles';
import type { ProductCardProps } from './types';

import classes from './ProductCard.module.css';

export default function ProductCard({ data }: ProductCardProps) {
  const isOpenToday = dateUtil.isToday(data.scheduleStart);

  const userLocation = storageUtil.local.get<ItemLocation>(
    config.storageKeys.userLocation
  );
  return (
    <Link className={classes['card-link']} href={data.productUrl}>
      <StyledCardContainer>
        <ProductImage image={data.productImage} />
        <StyledCardContent>
          <StyledCardContentTop>
            <aside>
              <Text as="subhead2">{data.productName}</Text>

              <Text as="body2" style={{ marginBottom: 4 }}>
                {data.vendorName}&nbsp;
                {userLocation && config.unicode.centerDotPadded}
                {userLocation &&
                  `${convertDistance(
                    getPreciseDistance(
                      userLocation, // TODO: Store location in root store
                      data.scheduleLocation.coordinates
                    ),
                    'mi'
                  ).toFixed(1)} mi.`}
              </Text>

              {data.scheduleAddress !== data.scheduleName && (
                <Text as="body2" truncate={2}>
                  {data.scheduleName}
                </Text>
              )}
              <Text as="body2" truncate={2}>
                {data.scheduleAddress}
              </Text>

              <Text as="body2">
                {isOpenToday
                  ? strings.labels.openToday
                  : dateUtil.format(
                      data.scheduleStart,
                      config.dateFormats.weekDayMonthDay
                    ) + ', '}
                {dateUtil
                  .format(data.scheduleStart, config.dateFormats.hourMin)
                  .toLowerCase()}
                &nbsp;&ndash;&nbsp;
                {dateUtil
                  .format(data.scheduleEnd, config.dateFormats.hourMin)
                  .toLowerCase()}
              </Text>
            </aside>

            <aside>
              {(data.productPrice || 0) > 0 && (
                <Text as="subhead2">
                  {numberUtil.renderPrice(data.productPrice || 0)}
                </Text>
              )}

              <StyledQuickInfo>
                <Icon
                  color="TextIconSecondary"
                  name={data.productLikes > 0 ? 'heart_filled' : 'heart'}
                  size={18}
                />

                {data.productLikes > 0 && (
                  <Text as="body2">&nbsp;({data.productLikes})</Text>
                )}
              </StyledQuickInfo>
            </aside>
          </StyledCardContentTop>

          {!!data.productDescription && (
            <StyledDescription as="body2">
              {data.productDescription}
            </StyledDescription>
          )}
        </StyledCardContent>
      </StyledCardContainer>
    </Link>
  );
}
