import { Text } from '@goodfynd/react-web.lib.ui';
import { styled } from '@goodfynd/react-web.theme';

import { CARD_IMAGE_DIMENSIONS } from './VendorImages/dimensions';

export const CARD_HEIGHT = 268;
export const CARD_WIDTH = CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH;

export const StyledCardContainer = styled('div', {
  borderRadius: 8,
  cursor: 'pointer',
  maxWidth: CARD_WIDTH,
  '&:hover': {
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  },
});

export const StyledCardContent = styled('div', {
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  height: CARD_HEIGHT - CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
  maxWidth: CARD_WIDTH,
  paddingLeft: 8,
  paddingTop: 12,
  '@md': {
    maxWidth: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
  },
});

export const StyledProductTypes = styled(Text, {
  truncate: 1,
});

export const StyledQuickInfo = styled('div', {
  alignItems: 'center',
  display: 'flex',
});
