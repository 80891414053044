import React, { useMemo, useRef } from 'react';
import { useWatchValue } from '@goodfynd/react-web.hooks.use-watch-value';
import type { MapMethods } from '@goodfynd/react-web.lib.ui';
import { FilterMenu } from '@goodfynd/react-web.lib.ui';
import { VendorEventMapCanvas } from '@goodfynd/react-web.maps.vendor-event-map';
import { Button } from '@goodfynd/react-web.ui.button';
import { IconButton } from '@goodfynd/react-web.ui.icon-button';
import Grid from '@mui/material/Grid';

import CmsPageContent from '../../../components/cms/CmsPageContent';
import { StyledFlex } from '../../../components/styles';
import config from '../../../config';
import env from '../../../config/env';
import strings from '../../../config/strings';
import { useLocation } from '../../../context/location-context';
import { useSearch } from '../../../context/search-context';
import CardsContainer from '../../../context/search-context/SearchResults/CardsContainer';
import {
  ScheduleMapCard,
  ScheduleMapPinHover,
} from '../../../widgets/ScheduleMapEmbed/ScheduleMapCard';
import { VendorCard } from '../VendorCard';

import { StyledContainer, StyledLoadMoreContainer } from './styles';
import type { VendorsOpenProps } from './types';

import classes from './VendorsOpen.module.css';

export default function VendorsOpen({
  busy,
  dayOptions = [],
  getDisplayIcon,
  hasNoResults,
  selected,
  setSelected,
  toggleDisplayIcon,
  vendors,
}: VendorsOpenProps) {
  const mapRef = useRef<MapMethods>(null);

  const { position } = useLocation();
  const {
    display,
    vendorSearch: {
      openVendorsSearch: {
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        total,
      },
    },
  } = useSearch();

  useWatchValue(position, (curPosition) => {
    if (curPosition) {
      mapRef.current?.panTo?.({
        lat: curPosition.latitude,
        lng: curPosition.longitude,
      });
    }
  });

  const isMapDisplay = useMemo(() => display === 'map', [display]);

  if (busy && !isMapDisplay) {
    return null;
  }

  return (
    <StyledContainer
      css={{
        '@mdMax': isMapDisplay
          ? {
              marginLeft: '-1rem',
              marginRight: '-1rem',
            }
          : undefined,
      }}
    >
      {!busy && (
        <>
          <StyledFlex
            css={{
              alignItems: 'center',
              backgroundColor: 'var(--BackgroundBase1)',
              gap: 8,
              '@mdMax': isMapDisplay
                ? {
                    margin: '-1rem 0',
                    padding: '1rem',
                    position: 'sticky',
                    top: 0,
                    zIndex: 999,
                  }
                : undefined,
            }}
          >
            <IconButton
              icon={getDisplayIcon()}
              onClick={toggleDisplayIcon}
              size="large"
              css={{
                '&.MuiIconButton-root': {
                  backgroundColor: 'var(--BackgroundFavorableSubtle)',
                  svg: {
                    color: 'var(--TextIconFavorable)',
                  },
                },
              }}
            />

            <FilterMenu
              className={classes['day-filter']}
              onChange={(option) => setSelected(option)}
              options={dayOptions}
              selected={selected || dayOptions[0]?.value}
            />
          </StyledFlex>

          {hasNoResults && <CmsPageContent slug="consumer-search-no-vendors" />}
        </>
      )}

      {isMapDisplay ? (
        <VendorEventMapCanvas
          apiKey={env.GOOGLE_API_KEY}
          busy={busy}
          defaultCenter={config.defaultLocation}
          defaultZoom={13}
          ref={mapRef}
          renderMarkerHoverInfo={(info) => <ScheduleMapPinHover item={info} />}
          results={vendors}
          css={{
            borderBottomRightRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
            marginTop: 24,
            overflow: 'hidden',
          }}
          renderListFooter={() =>
            hasNextPage && Number(total) > 0 ? (
              <StyledLoadMoreContainer>
                <Button
                  align="center"
                  busy={isFetchingNextPage}
                  disabled={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                  size="small"
                >
                  {strings.labels.loadMore}
                </Button>
              </StyledLoadMoreContainer>
            ) : (
              <></>
            )
          }
          renderListItem={
            vendors[0] &&
            (({ index = -1, point: { properties: item } }) => (
              <ScheduleMapCard
                item={item}
                number={index > -1 ? index + 1 : 0}
              />
            ))
          }
          sidebarCss={{
            '@lgMin': {
              border: '1px solid $Neutral05',
              borderRadius: '0.5rem',
              borderRight: 'none',
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              overflow: 'hidden',
            },
            '@mdMax': {
              height: '13.875rem',
            },
          }}
        />
      ) : (
        <>
          <CardsContainer>
            {vendors.map((item, index) => (
              <Grid item key={`${item.id}_${index + 1}`}>
                <VendorCard data={item} />
              </Grid>
            ))}
          </CardsContainer>

          {display !== 'map' && hasNextPage && Number(total) > 0 && (
            <StyledLoadMoreContainer>
              <Button
                busy={isFetchingNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                onClick={() => fetchNextPage()}
                size="medium"
              >
                {strings.labels.loadMore}
              </Button>
            </StyledLoadMoreContainer>
          )}
        </>
      )}
    </StyledContainer>
  );
}
