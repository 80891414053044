import { styled } from '../../../theme/stitches.config';

import { CARD_IMAGE_DIMENSIONS, DETAILS_IMAGE_DIMENSIONS } from './dimensions';

export const StyledContainer = styled('div', {
  backgroundColor: 'var(--BackgroundBase1)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderColor: '$Neutral01',
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: 0.25,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  overflow: 'hidden',
  variants: {
    fullWidth: {
      true: {
        width: CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
      },
    },
    location: {
      card: {
        '@sm': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
        '@md': {
          height: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
        },
        '@lg': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
      },
      details: {
        '@sm': {
          borderRadius: 0,
          height: DETAILS_IMAGE_DIMENSIONS.SM.CONTAINER_HEIGHT,
          width: '100%',
        },
        '@md': {
          height: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
        },
        '@lg': {
          height: DETAILS_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
      },
    },
  },
});
