import React from 'react';
import { Text } from '@goodfynd/react-web.lib.ui';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';

import strings from '../../config/strings';
import { useSearch } from '../../context/search-context';
import CardsContainer from '../../context/search-context/SearchResults/CardsContainer';

import { OpenVendorsResults } from './useOpenVendorsSearch/OpenVendorsResults';
import { VendorCard } from './VendorCard';

export const VendorResults = observer(() => {
  const {
    display,
    vendorSearch: { pages, total },
  } = useSearch();

  if (display === 'map') {
    return <OpenVendorsResults />;
  }

  return (
    <>
      <OpenVendorsResults />

      {Number(total) > 0 && (
        <Text as="heading4" style={{ marginTop: 32 }}>
          {strings.labels.allTrucks}
        </Text>
      )}

      <CardsContainer>
        {pages
          ?.map((page) => page.results)
          .map((items) =>
            items?.map((item) => (
              <Grid item key={item.id} lg={4} md={6} xs={12}>
                <VendorCard data={item} />
              </Grid>
            ))
          )}
      </CardsContainer>
    </>
  );
});
