import { useState } from 'react';
import type { QueryFunctionContext, QueryKey } from 'react-query';
import { useInfiniteQuery } from 'react-query';

import config from '../../config';
import { useLocation } from '../../context/location-context';
import type { SearchContextResults } from '../../context/search-context/types';
import { useVendorApi } from '../../hooks/api';
import type { VendorSearchResponse } from '../../services/api/types';
import type { SearchRequest } from '../../types/search';

import type { VendorSearchOptions } from './types';
import useOpenVendorsSearch from './useOpenVendorsSearch';

export default function useVendorSearch({
  context,
  phrase,
  rows = 0,
}: VendorSearchOptions): SearchContextResults<VendorSearchResponse> & {
  openVendorsSearch: ReturnType<typeof useOpenVendorsSearch>;
} {
  const api = useVendorApi();
  const { position } = useLocation();

  const [total, setTotal] = useState(0);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<VendorSearchResponse, unknown, VendorSearchResponse>(
    [
      config.queryKeys.searchVendors,
      `${position?.latitude},${position?.longitude}`,
    ],
    ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
      const request: SearchRequest = {
        page: pageParam || 1,
        query: phrase,
        rows,
      };

      if (Boolean(position?.latitude) && Boolean(position?.longitude)) {
        request.coordinates = `@${position?.latitude},${position?.longitude}`;
      }
      return api.search(request);
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.hasMore ? lastPage.page + 1 : undefined,
      onSuccess: (data) => {
        const pageData = data.pages.at(-1);
        if (!pageData?.results?.[0]) {
          return;
        }

        setTotal(pageData?.total);
      },
    }
  );

  const openVendorsSearch = useOpenVendorsSearch({ context, phrase, rows });

  return {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    openVendorsSearch,
    pages: data?.pages,
    refetch,
    total,
  };
}
