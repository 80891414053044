import { useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import config from '../../config';
import { useApp } from '../../context/app-context';
import { useLocation } from '../../context/location-context';
import { useEventApi } from '../../hooks/api';

import type { QueryFunctionContext, QueryKey } from 'react-query';

import type { EventSearchResponse } from '../../services/api/types';
import type { SearchRequest } from '../../types/search';
import type { EventSearchOptions } from './types';
import type { SearchContextResults } from '../../context/search-context/types';

export default function useEventSearch({
  context,
  phrase,
  rows = 0,
}: EventSearchOptions): SearchContextResults<EventSearchResponse> {
  const api = useEventApi();
  const [lastRequest, updateLastRequest] = useState<SearchRequest>({
    page: 1,
    phrase,
    rows,
  });
  const currentPage = useMemo(() => lastRequest.page || 1, [lastRequest.page]);

  const { isLoggedIn = false } = useApp();

  const { position } = useLocation();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<EventSearchResponse, unknown, EventSearchResponse>(
    [
      config.queryKeys.searchEvents,
      `${position?.latitude},${position?.longitude}`,
    ],
    ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
      const request: SearchRequest = {
        page: pageParam || 1,
        query: phrase,
        rows,
      };

      if (Boolean(position?.latitude) && Boolean(position?.longitude)) {
        request.coordinates = `@${position?.latitude},${position?.longitude}`;
      }

      updateLastRequest(request);
      return api.search(request);
    },
    {
      enabled: isLoggedIn,
      getNextPageParam: (prevResults: EventSearchResponse) =>
        prevResults.hasMore ? prevResults.page + 1 : undefined,
    }
  );

  const total = useMemo(
    () => data?.pages?.[currentPage - 1]?.total || 0,
    [currentPage, data?.pages]
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    pages: data?.pages,
    refetch,
    total,
  };
}
