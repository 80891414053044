import { styled } from '@goodfynd/react-web.theme';

export const StyledContainer = styled('section', {
  marginTop: 32,
});

export const StyledLoadMoreContainer = styled('section', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem 0',
  '@mdMax': {
    padding: 0,
  },
});
