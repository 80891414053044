import React from 'react';
import { Icon, Link, Text } from '@goodfynd/react-web.lib.ui';
import { getTimeDisplay } from '@goodfynd/react-web.utils.date-util';
import { convertDistance, getPreciseDistance } from 'geolib';

import config from '../../config';
import strings from '../../config/strings';
import * as storageUtil from '../../utils/storage-util';

import {
  StyledCardContainer,
  StyledCardContent,
  StyledProductTypes,
  StyledQuickInfo,
} from './styles';
import type { VendorCardProps } from './types';
import VendorImages from './VendorImages';

import classes from './VendorCard.module.css';

export function VendorCard({ data, showSchedule = true }: VendorCardProps) {
  const productTypeNames = (data.productTypes || data.vendorProductTypes)?.map(
    (productType) => productType.name
  );
  const hasProductTypes = !!productTypeNames?.[0];

  const userLocation = storageUtil.local.get<ItemLocation>(
    config.storageKeys.userLocation
  );

  const likes = data.likes || data.vendorLikes;
  return (
    <Link
      className={classes['card-link']}
      display="underline-none"
      href={
        config.app.mode.isProduction
          ? data.vendorUrl || data.url
          : `${config.env.APP_HOST}/vendors/${
              data.vendorNameId || data.vendorId || data.id
            }`
      }
    >
      <StyledCardContainer>
        <VendorImages
          image={data.image || data.vendorImage}
          productImages={data.topProductImages}
        />

        <StyledCardContent>
          <Text as="subhead2">{data.name || data.vendorName}</Text>

          <StyledQuickInfo>
            <Icon
              color="TextIconSecondary"
              name={likes ? 'heart_filled' : 'heart'}
              size={18}
            />
            {likes > 0 && <Text as="body2">&nbsp;({likes})</Text>}
            {hasProductTypes && (
              <StyledProductTypes as="body2">
                {config.unicode.centerDotPadded}
                {productTypeNames.join(', ')}
              </StyledProductTypes>
            )}
          </StyledQuickInfo>

          <StyledQuickInfo css={{ marginTop: 4 }}>
            {!!data.scheduleDay ? (
              <Text as="body2">
                {strings.labels.open}&nbsp;
                {getTimeDisplay(data.scheduleStart, false).toLowerCase()}-
                {getTimeDisplay(data.scheduleEnd, false).toLowerCase()}
                {userLocation &&
                  data.scheduleLocation &&
                  `${config.unicode.centerDotPadded}${convertDistance(
                    getPreciseDistance(
                      userLocation, // TODO: Store location in root store
                      data.scheduleLocation.coordinates
                    ),
                    'mi'
                  ).toFixed(1)} mi.`}
              </Text>
            ) : (
              showSchedule && <Text as="body2">{strings.labels.noEvents}</Text>
            )}
          </StyledQuickInfo>

          {!!data.scheduleAddress && (
            <StyledQuickInfo>
              <Text as="body2" truncate={1}>
                {data.scheduleAddress}
              </Text>
            </StyledQuickInfo>
          )}
        </StyledCardContent>
      </StyledCardContainer>
    </Link>
  );
}
